import React from 'react';

const MeetingsList = () => {
  return (
    <div>
      <h2>Meetings Archive</h2>
      <ul>
        <li>September 2020</li>
      </ul>
    </div>
  );
};

export default MeetingsList;
