import React from 'react';
import AccountForm from './AccountForm';

const EditAccount = () => {
  return (
    <div>
      <AccountForm />
    </div>
  );
};

export default EditAccount;
